import React, { useCallback } from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import Typed from 'react-typed';
import AnchorLink from 'react-anchor-link-smooth-scroll';

// Components
import Layout from '../../../../components/layout/Layout';
import WebsitesSimulatorSection from '../../../../components/section/simulator/Websites';
import WebsitesPlansSection from '../../../../components/section/plans/Websites';
import RealisationsSection from '../../../../components/section/Realisations';
import TestimonialsSection from '../../../../components/section/Testimonials';
import ContactForm from '../../../../components/form/Contact';

// Images
import headerBackgroundImage from '../../../../images/svg/components/wave-pattern-light.svg';

const CategoryWebsiteShowcaseRestaurantPage = ({ data }) => {

  const { t } = useTranslation();

  const location = useLocation();
  const result = queryString.parse(location.search);

  // Tawk Toggle
  const handleClickTawk = useCallback(
    e => {
      e.preventDefault();
      window.Tawk_API.toggle();
    },
    []
  );

  return (
    <Layout
      pageType="classic"
      pageTitle={t('meta.title')}
      pageDescription={t('meta.description')}
      pageKeywords={t('meta.keywords')}
      headerClass="navbar-absolute-top navbar-dark"
      headerStyle="dark"
    >
      <div>
        <div className="position-relative bg-dark overflow-hidden" style={{ backgroundImage: 'url(' + headerBackgroundImage + ')' }} >
          <div className="container position-relative zi-2 content-space-t-3 content-space-t-lg-4 content-space-b-2 content-space-b-md-3">
            <div className="row align-items-lg-center d-none d-md-flex" data-aos="fade-up">
              <div className="col-lg-5 mb-7 mb-lg-0">
                <div className="mb-6">
                  {result.name && result.city &&
                    <h1 className="h1 display-6 text-white mb-3" dangerouslySetInnerHTML={{ __html: t('title.nameAndCity', { title: data.site.siteMetadata.title, name: result.name, city_name: result.city }) }}></h1>
                  }
                  {result.name && !result.city &&
                    <h1 className="h1 display-6 text-white mb-3" dangerouslySetInnerHTML={{ __html: t('title.name', { title: data.site.siteMetadata.title, name: result.name, city_name: result.city }) }}></h1>
                  }
                  {!result.name && result.city &&
                    <h1 className="h1 display-6 text-white mb-3" dangerouslySetInnerHTML={{ __html: t('title.city', { title: data.site.siteMetadata.title, name: result.name, city_name: result.city }) }}></h1>
                  }
                  {!result.name && !result.city &&
                    <h1 className="h1 display-6 text-white mb-3" dangerouslySetInnerHTML={{ __html: t('title.none', { title: data.site.siteMetadata.title, name: result.name, city_name: result.city }) }}></h1>
                  }
                  <p className="lead text-white-70">{t('subtitle')}</p>
                </div>
                <span className="text-cap lead text-white mb-0">{t('partners.title', { title: data.site.siteMetadata.title })}</span>
                <div className="row">
                  <div className="col py-3">
                    <a href="https://partnernetwork.ionos.fr/partner/la.small.agency?origin=PartnerBadge" target="_blank" rel="noopener noreferrer">
                      <StaticImage imgClassName="img-fluid" quality="30" src="../../../../images/brands/ionos-agency-partner-icon.svg" alt={t('partners.ionos.image.alt', { title: t('common.image.alt') })} width={120} />
                    </a>
                  </div>
                </div>
                <div className="d-grid d-sm-flex gap-3">
                  <Link className="btn btn-primary btn-transition" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="position-relative">
                  <StaticImage className="img-fluid" quality="30" src="../../../../images/illustrations/categories/website/showcase/restaurant/la-small-agency-website-showcase-restaurant.svg" alt={t('image.alt', { title: t('common.image.alt') })} />
                </div>
              </div>
            </div>
            <div className="row align-items-lg-center d-md-none d-flex" data-aos="fade-up">
              <div className="col-lg-5 mb-7 mb-lg-0">
                <div className="position-relative mb-3">
                  <StaticImage className="img-fluid" quality="30" src="../../../../images/illustrations/categories/website/showcase/restaurant/la-small-agency-website-showcase-restaurant.svg" alt={t('image.alt', { title: t('common.image.alt') })} />
                </div>
                <div className="mb-5">
                  <div className="h1 display-6 text-white mb-3">{t('title')}
                    <span className="text-warning">
                      <Typed
                        strings={t('titleTyped').split(',')}
                        typeSpeed={40}
                        backSpeed={50}
                        loop >
                      </Typed>
                    </span>
                  </div>
                  <p className="lead text-white-70">{t('subtitle')}</p>
                </div>
                <span className="text-cap lead text-white mb-0">{t('partners.title', { title: data.site.siteMetadata.title })}</span>
                <div className="row">
                  <div className="col py-3">
                    <a href="https://partnernetwork.ionos.fr/partner/la.small.agency?origin=PartnerBadge" target="_blank" rel="noopener noreferrer">
                      <StaticImage imgClassName="img-fluid" quality="30" src="../../../../images/brands/ionos-agency-partner-icon.svg" alt={t('partners.ionos.image.alt', { title: t('common.image.alt') })} width={120} />
                    </a>
                  </div>
                </div>
                <div className="d-grid d-sm-flex gap-3">
                  <Link className="btn btn-primary btn-transition" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
                </div>
              </div>
            </div>
          </div>
          <div className="shape shape-bottom zi-1">
            <svg width="3000" height="500" viewBox="0 0 3000 500" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 500H3000V0L0 500Z" fill="#fff" />
            </svg>
          </div>
        </div>
        <div className="container content-space-t-2 content-space-b-md-2 content-space-t-lg-4">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2>{t('benefits.title')}</h2>
            <p>{t('benefits.subtitle')}</p>
          </div>
          <div className="row gx-3">
            <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
              <AnchorLink className="card card-sm card-transition h-100 pointer" href="#checklist">
                <div className="card-body">
                  <span className="svg-icon text-primary mb-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 21C6 21.6 6.4 22 7 22H17C17.6 22 18 21.6 18 21V20H6V21Z" fill="#035A4B"></path>
                      <path opacity="0.3" d="M17 2H7C6.4 2 6 2.4 6 3V20H18V3C18 2.4 17.6 2 17 2Z" fill="#035A4B"></path>
                      <path d="M12 4C11.4 4 11 3.6 11 3V2H13V3C13 3.6 12.6 4 12 4Z" fill="#035A4B"></path>
                    </svg>
                  </span>
                  <div className="h4 card-title">{t('benefits.responsive.title')}</div>
                  <p className="card-text text-body">{t('benefits.responsive.subtitle')}</p>
                </div>
                <div className="card-footer pt-0">
                  <span className="card-link">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></span>
                </div>
              </AnchorLink>
            </div>
            <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
              <AnchorLink className="card card-sm card-transition h-100 pointer" href="#checklist">
                <div className="card-body">
                  <span className="svg-icon text-primary mb-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="#035A4B"></path>
                      <path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="#035A4B"></path>
                    </svg>
                  </span>
                  <div className="h4 card-title">{t('benefits.personalized.title')}</div>
                  <p className="card-text text-body">{t('benefits.personalized.subtitle')}</p>
                </div>
                <div className="card-footer pt-0">
                  <span className="card-link">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></span>
                </div>
              </AnchorLink>
            </div>
            <div className="col-sm-6 col-lg-3 mb-3 mb-sm-0">
              <AnchorLink className="card card-sm card-transition h-100 pointer" href="#checklist">
                <div className="card-body">
                  <span className="svg-icon text-primary mb-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="#035A4B"></path>
                      <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="#035A4B"></path>
                    </svg>
                  </span>
                  <div className="h4 card-title">{t('benefits.seoFriendly.title')}</div>
                  <p className="card-text text-body">{t('benefits.seoFriendly.subtitle')}</p>
                  <div className="text-dark align-middle">
                    <span className="me-2" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('benefits.seoFriendly.google')}>
                      <StaticImage className="avatar avatar-xss" imgClassName="avatar-img" quality="30" src="../../../../images/brands/google-icon.svg" alt={t('benefits.seoFriendly.google')} />
                    </span>
                    <span className="me-2" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('benefits.seoFriendly.bing')}>
                      <StaticImage className="avatar avatar-xss" imgClassName="avatar-img" quality="30" src="../../../../images/brands/bing-icon.svg" alt={t('benefits.seoFriendly.bing')} />
                    </span>
                    <span className="me-2" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('benefits.seoFriendly.yahoo')}>
                      <StaticImage className="avatar avatar-xss" imgClassName="avatar-img" quality="30" src="../../../../images/brands/yahoo-icon.svg" alt={t('benefits.seoFriendly.yahoo')} />
                    </span>
                    <span className="me-2" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('benefits.seoFriendly.yandex')}>
                      <StaticImage className="avatar avatar-xss" imgClassName="avatar-img" quality="30" src="../../../../images/brands/yandex-icon.svg" alt={t('benefits.seoFriendly.yandex')} />
                    </span>
                  </div>
                </div>
                <div className="card-footer pt-0">
                  <span className="card-link">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></span>
                </div>
              </AnchorLink>
            </div>
            <div className="col-sm-6 col-lg-3">
              <AnchorLink className="card card-sm card-transition h-100 pointer" href="#checklist">
                <div className="card-body">
                  <span className="svg-icon text-primary mb-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M4 4L11.6314 2.56911C11.875 2.52343 12.125 2.52343 12.3686 2.56911L20 4V11.9033C20 15.696 18.0462 19.2211 14.83 21.2313L12.53 22.6687C12.2057 22.8714 11.7943 22.8714 11.47 22.6687L9.17001 21.2313C5.95382 19.2211 4 15.696 4 11.9033L4 4Z" fill="#035A4B"></path>
                      <path fillRule="evenodd" clipRule="evenodd" d="M9.5 10.5C9.5 9.11929 10.6193 8 12 8C13.3807 8 14.5 9.11929 14.5 10.5V11C15.0523 11 15.5 11.4477 15.5 12V15C15.5 15.5523 15.0523 16 14.5 16H9.5C8.94772 16 8.5 15.5523 8.5 15V12C8.5 11.4477 8.94772 11 9.5 11V10.5ZM12 9C11.1716 9 10.5 9.67157 10.5 10.5V11H13.5V10.5C13.5 9.67157 12.8284 9 12 9Z" fill="#035A4B"></path>
                    </svg>
                  </span>
                  <div className="h4 card-title">{t('benefits.safe.title')}</div>
                  <p className="card-text text-body">{t('benefits.safe.subtitle')}</p>
                </div>
                <div className="card-footer pt-0">
                  <span className="card-link">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></span>
                </div>
              </AnchorLink>
            </div>
          </div>
        </div>
        <div className="overflow-hidden bg-light" id="checklist">
          <div className="container content-space-2 content-space-lg-3">
            <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
              {result.name && result.city &&
                <h2 dangerouslySetInnerHTML={{ __html: t('checklist.title.nameAndCity', { title: data.site.siteMetadata.title, name: result.name, city_name: result.city }) }}></h2>
              }
              {result.name && !result.city &&
                <h2 dangerouslySetInnerHTML={{ __html: t('checklist.title.name', { title: data.site.siteMetadata.title, name: result.name, city_name: result.city }) }}></h2>
              }
              {!result.name && result.city &&
                <h2 dangerouslySetInnerHTML={{ __html: t('checklist.title.city', { title: data.site.siteMetadata.title, name: result.name, city_name: result.city }) }}></h2>
              }
              {!result.name && !result.city &&
                <h2 dangerouslySetInnerHTML={{ __html: t('checklist.title.none', { title: data.site.siteMetadata.title, name: result.name, city_name: result.city }) }}></h2>
              }
            </div>
            <div className="w-md-100 mx-md-auto">
              <div className="row justify-content-lg-center align-items-md-center">
                <div className="col-12 col-md-5 mb-7 m-md-0">
                  <div className="position-relative">
                    <div className="card">
                      <div className="card-body">
                        <span className="svg-icon text-primary mb-4">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#035A4B"></path>
                            <path d="M19 10.4C19 10.3 19 10.2 19 10C19 8.9 18.1 8 17 8H16.9C15.6 6.2 14.6 4.29995 13.9 2.19995C13.3 2.09995 12.6 2 12 2C11.9 2 11.8 2 11.7 2C12.4 4.6 13.5 7.10005 15.1 9.30005C15 9.50005 15 9.7 15 10C15 11.1 15.9 12 17 12C17.1 12 17.3 12 17.4 11.9C18.6 13 19.9 14 21.4 14.8C21.4 14.8 21.5 14.8 21.5 14.9C21.7 14.2 21.8 13.5 21.9 12.7C20.9 12.1 19.9 11.3 19 10.4Z" fill="#035A4B"></path>
                            <path d="M12 15C11 13.1 10.2 11.2 9.60001 9.19995C9.90001 8.89995 10 8.4 10 8C10 7.1 9.40001 6.39998 8.70001 6.09998C8.40001 4.99998 8.20001 3.90005 8.00001 2.80005C7.30001 3.10005 6.70001 3.40002 6.20001 3.90002C6.40001 4.80002 6.50001 5.6 6.80001 6.5C6.40001 6.9 6.10001 7.4 6.10001 8C6.10001 9 6.80001 9.8 7.80001 10C8.30001 11.6 9.00001 13.2 9.70001 14.7C7.10001 13.2 4.70001 11.5 2.40001 9.5C2.20001 10.3 2.10001 11.1 2.10001 11.9C4.60001 13.9 7.30001 15.7 10.1 17.2C10.2 18.2 11 19 12 19C12.6 20 13.2 20.9 13.9 21.8C14.6 21.7 15.3 21.5 15.9 21.2C15.4 20.5 14.9 19.8 14.4 19.1C15.5 19.5 16.5 19.9 17.6 20.2C18.3 19.8 18.9 19.2 19.4 18.6C17.6 18.1 15.7 17.5 14 16.7C13.9 15.8 13.1 15 12 15Z" fill="#035A4B"></path>
                          </svg>
                        </span>
                        <h3 className="card-title">{t('checklist.card.title')}</h3>
                        <p className="card-text">{t('checklist.card.subtitle')}</p>
                      </div>
                    </div>
                    <figure className="position-absolute bottom-0 start-0 zi-n1 mb-n7 ms-n7" style={{ width: '12rem' }}>
                      <StaticImage className="img-fluid" quality="30" src="../../../../images/svg/components/dots-lg.svg" alt={t('image.alt', { title: t('common.image.alt') })} />
                    </figure>
                  </div>
                </div>
                <div className="col-12 col-md-7">
                  <div className="row justify-content-lg-center align-items-md-center">
                    <div className="col-6 col-lg-6">
                      <ul className="list-checked list-checked-soft-bg-primary list-checked-lg mb-5">
                        <li className="list-checked-item">{t('checklist.navigation')}</li>
                        <li className="list-checked-item">{t('checklist.presentation')}</li>
                        <li className="list-checked-item">{t('checklist.awards')}</li>
                        <li className="list-checked-item">{t('checklist.services')}</li>
                        <li className="list-checked-item">{t('checklist.gallery')}</li>
                        <li className="list-checked-item">{t('checklist.menus')}</li>
                        <li className="list-checked-item">{t('checklist.booking')}</li>
                        <li className="list-checked-item">{t('checklist.testimonials')}</li>
                        <li className="list-checked-item">{t('checklist.partners')}</li>
                        <li className="list-checked-item">{t('checklist.about')}</li>
                        <li className="list-checked-item">{t('checklist.press')}</li>
                      </ul>
                    </div>
                    <div className="col-6 col-lg-6">
                      <ul className="list-checked list-checked-soft-bg-primary list-checked-lg mb-5">
                        <li className="list-checked-item">{t('checklist.team')}</li>
                        <li className="list-checked-item">{t('checklist.contacts')}</li>
                        <li className="list-checked-item">{t('checklist.openinHours')}</li>
                        <li className="list-checked-item">{t('checklist.links')}</li>
                        <li className="list-checked-item">{t('checklist.socialNetworks')}</li>
                        <li className="list-checked-item">{t('checklist.googleMaps')}</li>
                        <li className="list-checked-item">{t('checklist.chat')}</li>
                        <li className="list-checked-item">{t('checklist.privacyPolicy')}</li>
                        <li className="list-checked-item">{t('checklist.termsService')}</li>
                        <li className="list-checked-item">{t('checklist.cookiePolicy')}</li>
                      </ul>
                    </div>
                  </div>
                  <AnchorLink className="link" href="#plans">{t('cta.ourPlans')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container content-space-2">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2 dangerouslySetInnerHTML={{ __html: t('process.title') }}></h2>
          </div>
          <div className="row align-items-lg-center">
            <div className="col-lg-5 order-lg-2 mb-7 mb-lg-0">
              <div>
                <div className="nav nav-sm nav-pills nav-pills-shadow flex-lg-column gap-lg-1 p-3 row" role="tablist">
                  <a className="nav-item nav-link col-12 active" href="#process-conversation" id="process-conversation-tab" data-bs-toggle="tab" data-bs-target="#process-conversation" role="tab" aria-controls="process-conversation" aria-selected="true" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M22.1671 18.1421C22.4827 18.4577 23.0222 18.2331 23.0206 17.7868L23.0039 13.1053V5.52632C23.0039 4.13107 21.8729 3 20.4776 3H8.68815C7.2929 3 6.16183 4.13107 6.16183 5.52632V9H13C14.6568 9 16 10.3431 16 12V15.6316H19.6565L22.1671 18.1421Z" fill="#035A4B"></path>
                          <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M1.98508 18V13C1.98508 11.8954 2.88051 11 3.98508 11H11.9851C13.0896 11 13.9851 11.8954 13.9851 13V18C13.9851 19.1046 13.0896 20 11.9851 20H4.10081L2.85695 21.1905C2.53895 21.4949 2.01123 21.2695 2.01123 20.8293V18.3243C1.99402 18.2187 1.98508 18.1104 1.98508 18ZM5.99999 14.5C5.99999 14.2239 6.22385 14 6.49999 14H11.5C11.7761 14 12 14.2239 12 14.5C12 14.7761 11.7761 15 11.5 15H6.49999C6.22385 15 5.99999 14.7761 5.99999 14.5ZM9.49999 16C9.22385 16 8.99999 16.2239 8.99999 16.5C8.99999 16.7761 9.22385 17 9.49999 17H11.5C11.7761 17 12 16.7761 12 16.5C12 16.2239 11.7761 16 11.5 16H9.49999Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.conversation.title')}</div>
                        <p className="text-body mb-0">{t('process.conversation.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-design" id="process-design-tab" data-bs-toggle="tab" data-bs-target="#process-design" role="tab" aria-controls="process-design" aria-selected="false" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M6.45801 14.775L9.22501 17.542C11.1559 16.3304 12.9585 14.9255 14.605 13.349L10.651 9.39502C9.07452 11.0415 7.66962 12.8441 6.45801 14.775V14.775Z" fill="#035A4B"></path>
                          <path d="M9.19301 17.51C9.03401 19.936 6.76701 21.196 3.55701 21.935C3.34699 21.9838 3.12802 21.9782 2.92074 21.9189C2.71346 21.8596 2.52471 21.7484 2.37231 21.5959C2.2199 21.4434 2.10886 21.2545 2.04967 21.0472C1.99048 20.8399 1.98509 20.6209 2.034 20.411C2.772 17.201 4.03401 14.934 6.45801 14.775L9.19301 17.51ZM21.768 4.43697C21.9476 4.13006 22.0204 3.77232 21.9751 3.41963C21.9297 3.06694 21.7687 2.73919 21.5172 2.48775C21.2658 2.2363 20.9381 2.07524 20.5854 2.02986C20.2327 1.98449 19.8749 2.0574 19.568 2.23701C16.2817 4.20292 13.2827 6.61333 10.656 9.39998L14.61 13.354C17.395 10.7252 19.8037 7.72455 21.768 4.43697V4.43697Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.design.title')}</div>
                        <p className="text-body mb-0">{t('process.design.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-build" id="process-build-tab" data-bs-toggle="tab" data-bs-target="#process-build" role="tab" aria-controls="process-build" aria-selected="false" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.2718 8.68537C16.8933 8.28319 16.9125 7.65032 17.3146 7.2718C17.7168 6.89329 18.3497 6.91246 18.7282 7.31464L22.7282 11.5646C23.0906 11.9497 23.0906 12.5503 22.7282 12.9354L18.7282 17.1854C18.3497 17.5875 17.7168 17.6067 17.3146 17.2282C16.9125 16.8497 16.8933 16.2168 17.2718 15.8146L20.6268 12.25L17.2718 8.68537Z" fill="#035A4B"></path>
                          <path d="M6.7282 8.68537C7.10671 8.28319 7.08754 7.65032 6.68536 7.2718C6.28319 6.89329 5.65031 6.91246 5.2718 7.31464L1.2718 11.5646C0.909397 11.9497 0.909397 12.5503 1.2718 12.9354L5.2718 17.1854C5.65031 17.5875 6.28319 17.6067 6.68536 17.2282C7.08754 16.8497 7.10671 16.2168 6.7282 15.8146L3.37325 12.25L6.7282 8.68537Z" fill="#035A4B"></path>
                          <rect opacity="0.3" x="12.7388" y="3.97168" width="2" height="16" rx="1" transform="rotate(12.3829 12.7388 3.97168)" fill="#035A4B"></rect>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.build.title')}</div>
                        <p className="text-body mb-0">{t('process.build.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-launch" id="process-launch-tab" data-bs-toggle="tab" data-bs-target="#process-launch" role="tab" aria-controls="process-launch" aria-selected="false" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z" fill="#035A4B"></path>
                          <path d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982V15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442V19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.publish.title')}</div>
                        <p className="text-body mb-0">{t('process.publish.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-follow-up" id="process-follow-up-tab" data-bs-toggle="tab" data-bs-target="#process-follow-up" role="tab" aria-controls="process-follow-up" aria-selected="false" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="#035A4B"></path>
                          <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.followUp.title')}</div>
                        <p className="text-body mb-0">{t('process.followUp.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 order-lg-1">
              <div className="tab-content">
                <div className="tab-pane fade show active" id="process-conversation" role="tabpanel" aria-labelledby="processOne-tab">
                  <StaticImage className="device-mobile-img" quality="30" src="../../../../images/illustrations/categories/website/showcase/restaurant/la-small-agency-showcase-process-conversation.svg" alt={t('process.conversation.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.conversation.text')}</p>
                </div>
                <div className="tab-pane fade" id="process-design" role="tabpanel" aria-labelledby="process-design-tab">
                  <StaticImage className="device-mobile-img" quality="30" src="../../../../images/illustrations/categories/website/showcase/restaurant/la-small-agency-showcase-process-design.svg" alt={t('process.design.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.design.text')}</p>
                </div>
                <div className="tab-pane fade" id="process-build" role="tabpanel" aria-labelledby="process-build-tab">
                  <StaticImage className="device-mobile-img" quality="30" src="../../../../images/illustrations/categories/website/showcase/restaurant/la-small-agency-showcase-process-build.svg" alt={t('process.build.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.build.text')}</p>
                </div>
                <div className="tab-pane fade" id="process-launch" role="tabpanel" aria-labelledby="process-launch-tab">
                  <StaticImage className="device-mobile-img" quality="30" src="../../../../images/illustrations/categories/website/showcase/restaurant/la-small-agency-showcase-process-launch.svg" alt={t('process.publish.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.publish.text')}</p>
                </div>
                <div className="tab-pane fade" id="process-follow-up" role="tabpanel" aria-labelledby="process-follow-up-tab">
                  <StaticImage className="device-mobile-img" quality="30" src="../../../../images/illustrations/categories/website/showcase/restaurant/la-small-agency-showcase-process-follow-up.svg" alt={t('process.followUp.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.followUp.text')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mx-md-auto mt-2 mb-5 mb-md-9" data-aos="fade-up">
            <button className="btn btn-primary btn-transition" onClick={handleClickTawk}><i className="bi bi-chat me-1"></i>{t('cta.letsTalk')}</button>
          </div>
        </div>
        <div className="container content-space-2" data-aos="fade-up" data-aos-delay="300">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <span className="text-cap">{t('technologies.title')}</span>
            <h2>{t('technologies.subtitle')}</h2>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4 mb-4 mb-md-5 mb-lg-0">
              <a className="card card-lg card-transition h-100 text-center" href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">
                <div className="card-body">
                  <div className="mb-4">
                    <StaticImage className="avatar" quality="30" src="../../../../images/brands/react-icon.svg" alt="React" />
                  </div>
                  <h3 className="card-title">{t('technologies.react.title')}</h3>
                  <p className="card-text text-body">{t('technologies.react.subtitle')}</p>
                </div>
                <div className="card-footer pt-0">
                  <span className="card-link">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></span>
                </div>
              </a>
            </div>
            <div className="col-md-6 col-lg-4">
              <a className="card card-lg card-transition h-100 text-center" href="https://nextjs.org/" target="_blank" rel="noopener noreferrer">
                <div className="card-body">
                  <div className="mb-4">
                    <StaticImage className="avatar" quality="30" src="../../../../images/brands/next-js-icon.svg" alt="Next.js" />
                  </div>
                  <h3 className="card-title">{t('technologies.nextjs.title')}</h3>
                  <p className="card-text text-body">{t('technologies.nextjs.subtitle')}</p>
                </div>
                <div className="card-footer pt-0">
                  <span className="card-link">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></span>
                </div>
              </a>
            </div>
            <div className="col-md-6 col-lg-4 mb-4 mb-md-5 mb-lg-0">
              <a className="card card-lg card-transition h-100 text-center" href="https://www.gatsbyjs.com/" target="_blank" rel="noopener noreferrer">
                <div className="card-body">
                  <div className="mb-4">
                    <StaticImage className="avatar" quality="30" src="../../../../images/brands/gatsbyjs-icon.svg" alt="Gatsby" />
                  </div>
                  <h3 className="card-title">{t('technologies.gatsby.title')}</h3>
                  <p className="card-text text-body">{t('technologies.gatsby.subtitle')}</p>
                </div>
                <div className="card-footer pt-0">
                  <span className="card-link">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></span>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="bg-light">
          <div className="container content-space-2 content-space-lg-3">
            <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
              <h2>{t('simulator.title')}</h2>
              <p>{t('simulator.subtitle')}</p>
            </div>
            <WebsitesSimulatorSection data={data} plan={data.plan.frontmatter} service="showcase" />
          </div>
        </div>
        <div className="container content-space-2 content-space-lg-3">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2 dangerouslySetInnerHTML={{ __html: t('plans.title') }}></h2>
            <p>{t('plans.subtitle')}</p>
          </div>
          <WebsitesPlansSection data={data} plan={data.plan.frontmatter} service="showcase" />
          <div className="text-center aos-init aos-animate" data-aos="fade-up">
            <div className="card card-info-link card-sm mb-5 mb-md-0">
              <div className="card-body">
                {t('cta.needMoreInfos')} <Link className="card-link ms-2" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
              </div>
            </div>
          </div>
        </div>
        {data.realisations.nodes.length ?
          <div className="bg-light">
            <div className="container content-space-2 content-space-lg-3">
              <div className="w-md-75 text-center mx-md-auto mb-5">
                <h2>{t('realisations.title', { title: data.site.siteMetadata.title })}</h2>
                <p>{t('realisations.subtitle', { title: data.site.siteMetadata.title })}</p>
              </div>
              <RealisationsSection data={data} realisations={data.realisations} service="home" />
              <div className="text-center aos-init aos-animate" data-aos="fade-up">
                <div className="card card-info-link card-sm mb-5 mb-md-0">
                  <div className="card-body">
                    <Link className="card-link ms-2" to="/realisations/">{t('realisations.button')} <span className="bi-chevron-right small ms-1"></span></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null}
        {data.testimonials.nodes.length ?
          <div className="container content-space-2 content-space-lg-3">
            <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
              <h2>{t('testimonials.title')}</h2>
            </div>
            <TestimonialsSection data={data} testimonials={data.testimonials} sectionStyle="1" service="home" />
            <div className="text-center aos-init aos-animate" data-aos="fade-up">
              <div className="card card-info-link card-sm mb-5 mb-md-0">
                <div className="card-body">
                  {t('cta.needMoreInfos')} <Link className="card-link ms-2" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
                </div>
              </div>
            </div>
          </div>
          : null}
        <div className="container content-space-2 content-space-b-lg-4">
          <div className="bg-dark rounded-2" style={{ backgroundImage: 'url(' + headerBackgroundImage + ')' }} >
            <div className="px-4 px-md-8 px-lg-10">
              <div className="row justify-content-lg-between align-items-lg-center">
                <div className="col-lg-5 col-md-10  mb-9 mt-9 mb-lg-0 mt-lg-0">
                  <h1 className="text-white">{t('contact.title', { title: data.site.siteMetadata.title })}</h1>
                  <p className="text-white-70">{t('contact.subtitle', { title: data.site.siteMetadata.title })}</p>
                  <div className="border-top border-white-10 my-5" style={{ maxWidth: '10rem' }}></div>
                  <div className="row">
                    {data.site.siteMetadata.telephone ?
                      <div className="col-sm-6 mb-5">
                        <span className="icon icon-soft-light icon-circle mb-3">
                          <i className="bi-telephone-inbound-fill"></i>
                        </span>
                        <h4 className="text-white mb-0">{t('contact.callUs')}</h4>
                        <a className="text-white-70 small" href={"tel:" + data.site.siteMetadata.telephoneIndex}>{data.site.siteMetadata.telephone}</a>
                      </div>
                      : null}
                    {data.site.siteMetadata.email ?
                      <div className="col-sm-6 mb-5">
                        <span className="icon icon-soft-light icon-circle mb-3">
                          <i className="bi-envelope-open-fill"></i>
                        </span>
                        <h4 className="text-white mb-0">{t('contact.emailUs')}</h4>
                        <a className="text-white-70 small" href={"mailto:" + data.site.siteMetadata.email}>{data.site.siteMetadata.email}</a>
                      </div>
                      : null}
                    {data.site.siteMetadata.addressFull ?
                      <div className="col-sm-6">
                        <span className="icon icon-soft-light icon-circle mb-3">
                          <i className="bi-geo-alt-fill"></i>
                        </span>
                        <h4 className="text-white mb-0">{t('contact.visitUs')}</h4>
                        <a className="text-white-70 small" href={"http://maps.google.com/maps?daddr=" + data.site.siteMetadata.addressLatitude + "%2C" + data.site.siteMetadata.addressLongitude} target="_blank" rel="noopener noreferrer">{data.site.siteMetadata.addressFull}</a>
                      </div>
                      : null}
                  </div>
                </div>
                <div className="col-lg-7 mb-9 mt-9 mb-lg-n10 mt-lg-n10">
                  <div className="card card-lg">
                    <div className="card-header bg-light text-center">
                      <h3 className="card-title">{t('contact.text')}</h3>
                    </div>
                    <div className="card-body">
                      <ContactForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CategoryWebsiteShowcaseRestaurantPage

export const query = graphql`
  query ($language: String!) {
    site: site {
      siteMetadata {
        siteUrl
        title
        addressStreet
        addressPostalCode
        addressCity
        addressCountry
        addressFull
        addressGoogleDirection
        addressLatitude
        addressLongitude
        email
        telephone
        telephoneIndex
      }
    }
    locales: allLocale(filter: {ns: {in: ["common", "header", "category-website-showcase-restaurant", "plans", "simulator-websites", "categories", "tools", "contact-form", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    realisations: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/realisations/" },
        frontmatter: { services: { in: ["showcase"] }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date, order: DESC}
      limit: 3
    ) {
      nodes {
        frontmatter {
          meta {
            title
            description
            keywords
          }
          title
          category
          tags
          technologies
          languages
          description
          client
          partners
          services
          image {
            card {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
            alt
          }
          home
          active
          language
          path
          date(formatString: "MMMM YYYY", locale: $language)
        }
        id
        timeToRead
      }
      totalCount
    }
    testimonials: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/testimonials/" },
        frontmatter: { pages: {services: { in: ["showcase"] } }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date, order: DESC}
    )
    {
      nodes {
        frontmatter {
          name
          rating
          from
          link
          pages {
            services
            realisations
          }
          active
          language
          date(formatString: "D MMMM YYYY", locale: $language)
        }
        id
        slug
        body
      }
    }
    plan: mdx(slug: {eq: "showcase"}, fileAbsolutePath: { regex: "/data/plans/" }) {
      frontmatter {
        price
        server {
            month_price
            year_price
        }
        custom {
          pages {
              active
              included
              number_minimum
              number_maximum
              additional_price
          }
          sheets {
              active
              included
              number_minimum
              number_maximum
              additional_price
          }
          plugins {
              active
              included
              number_minimum
              number_maximum
              additional_price
          }
          languages {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
              page_price
              sheet_price
              plugin_price
              translation_price
          }
          delivered {
              active
              install
              page
              sheet
              plugin
              language {
                install
                page
                sheet
                plugin
              }
              launch
          }
          modifications {
              active
              every_price,
              number_minimum,
              number_maximum,
          }
        }
      }
      id
    }
  }
`;
